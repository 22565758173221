import React from "react";
import Layout from 'layouts/layout'
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "assets/jss/nextjs-material-kit/pages/landingPage.js";

const UseStyles = makeStyles(styles);

const miod = {
  color: '#ca9502'
}

export default function dzienJednosci2019() {
  const classes = UseStyles();
  return (
    <Layout>
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div className={classes.container} style={{ display: 'flex' }}>
          <div style={{ color: 'black', padding: '5px ', margin: '0 auto' }} >
            <img src={'/generalSlides/szkola-animatora-1-2019.jpg'} alt={'plakat szkoła animatora 1 2019'} style={{ padding: '30px', margin: '5vw auto', width: '100%' }} />
            <div>
              <h2 className="miod"><strong style={miod}>DIECEZJALNA SZKOŁA ANIMATORA</strong> </h2>
              <p><em>SPOTKANIE PIERWSZE - 26.10.2019 r. w DAR Resurrectit</em> </p>
              <figure>
                <figcaption>Posłuchaj: </figcaption>
                <audio
                  style={{width: '100%'}}
                  controls
                  src="/SchoolAnimation1.mp3">
                    Twoja przeglądarka nie wspiera tagów <code>audio</code>.
                </audio>
              </figure>

              <p className="miod"><strong style={miod}>Notatka ze spotkania:</strong></p>

              <p className="miod"><strong style={miod}>Godzina: 10:00</strong></p>
              <h4>Zajęcia w szkole:</h4>
              <p>Pieśń na rozpoczęcie Szkoły: „Duchu Św. wołam przyjdź…”</p>
              <p>Losowanie posług przyziemnych i duchowych na pierwsze spotkanie Szkoły Animatora. </p>
              <p>Modlitwa do Ducha Św. na rozpoczęcie.</p>
              <h4><em>Duch Święty i my:</em></h4>
              <p><em>Otwieram się na Niego; w Nim cała moja Szkoła Animatora. </em></p>
              <p><em>Duch Św., jak zwykle, trafiał bezbłędnie! </em></p>
              <p><em>Tylko On może nas prowadzić „po właściwych ścieżkach”.</em></p>

              <p className="miod"><strong style={miod}>10:30</strong></p>
              <p>Inauguracyjny wykład ks. Artura Kasprzyckiego: „Animator idealny – misja, sylwetka, wyzwania i rozwój.”  obejmował głównie:</p>
              <ul>
                <li>nasze braki w pracy w Odnowie</li>
                <li>rozeznanie co do naszego działania w Odnowie (wspomnienie o rozeznaniu z dnia 25.05.2019r.)</li>
                <li>zewnętrzna i wewnętrzna perspektywa aktywności animatora</li>
                <li>animator idealny i jako pomocnik lidera.</li>
              </ul>
              <p><em>Duch Św. będzie miał sporo pracy z nami (a my z nim)!</em></p>

              <p className="miod"><strong style={miod}>11:30</strong></p>
              <p>Adoracja: nasze osobiste i wspólnotowe spotkanie z Jezusem w postaci chleba. Modlitwy dotyczyły szerokiego spektrum intencji dotyczących Kościoła, Odnowy i nas samych. </p>
              <p><em>Otwieram się całkowicie na Ducha Św. W chwale Bożej wszystko może się wydarzyć! </em></p>

              <p className="miod"><strong style={miod}>12:00</strong></p>
              <p>Eucharystia jednoczy naszą wspólnotę w kaplicy Duszpasterstwa. Homilię wygłosił ks. Janusz Rył. Dotyczyła naszej roli jako animatora w oparciu o ewangelię z dnia, tj. Łk 13, 1-9.</p>
              <p><em>Duch Św. perfekcyjnie skoordynował słowo Boże z dnia z tematem homilii, tj. z naszymi szczegółowymi potrzebami/zadaniami  animatorów.  </em></p>

              <p className="miod"><strong style={miod}>13:00</strong></p>
              <p>Przerwa w naszej pracy.</p>
              <p><em>Duch Św. dobrze rozumie nasze doczesne potrzeby.</em></p>

              <p className="miod"><strong style={miod}>13:30</strong></p>
              <p>Warsztat obejmował 2 główne punkty:</p>
              <p>1) Anonimowa ankieta na start dla uczestników spotkania pt. „Ja w posłudze?” W 20 punktach wypowiadaliśmy się na tematy związane z posługą lidera.</p>
              <p>2) Różne uwagi</p>
              <ul>
                <li>działania nieschematyczne we wspólnotach</li>
                <li>otwartość na osoby spoza Odnowy </li>
                <li>możliwość zmiany tematyki Szkoły Animatora w trakcie kursu </li>
                <li>Szkoła zakłada „pracę domową” czyli aktywność między spotkaniami </li>
                <li>inne</li>
              </ul>
              <p><em>Oprócz Ducha Św., również ks. Artur chce znać nasze opinie na kilka ważnych tematów. </em></p>
              <p><em>Duch Św. jest obecny we wszystkich naszych sprawach – nawet w tych drugorzędnych. </em></p>

              <p className="miod"><strong style={miod}>15:00</strong></p>
              <p>Koronka do Miłosierdzia Bożego w kaplicy.</p>
              <p><em>Duch Św. modli się z nami i w nas.</em></p>

              <p className="miod"><strong style={miod}>15:15</strong></p>
              <p>Panel dyskusyjny obejmował w skrócie:</p>
              <ul>
                <li>pozytywną opinię o losowaniu posług </li>
                <li>„Nieplanowane” – propozycja do kina w najbliższym czasie</li>
                <li>świadectwa i zwrotne informacje z pierwszego spotkania w ramach Szkoły Animatora</li>
                <li>radość ze wspólnego pobytu i modlitwy! </li>
              </ul>
              <p><em>Ewidentna otwartość i radość uczestników – to nie mogło być bez Ducha Św.</em></p>

              <p className="miod"><strong style={miod}>15:30</strong></p>
              <p>Błogosławieństwo na rozesłanie.</p>
              <p><em>Duch Św. z mocą towarzyszy nam każdego dnia.</em></p>

              <img src={'/animator-1/01.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/02.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/03.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/04.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/05.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/06.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/07.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/08.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/09.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/10.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/11.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/12.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/13.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/14.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/15.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/16.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/17.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/18.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/19.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/20.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/21.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/22.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/23.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/24.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/25.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/26.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/27.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/28.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/29.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
              <img src={'/animator-1/30.jpeg'} alt={''} style={{ padding: '10px', margin: '0', width: '50%' }} />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}
